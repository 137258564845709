import { useCallback, useState } from 'react';
import { PromptStatus } from '@remento/types/project';

import { useAsyncEffect } from '@/hooks';
import { useServices } from '@/Services';

import { useUser } from '../auth/auth.service.hook';

import { useFilterProjectPromptIds, useProjectQuery } from './project.service.hook';
import { ProjectBanner } from './project-banner.types';

export function useProjectBanner(projectId: string) {
  const { projectBannerService } = useServices();

  const user = useUser();
  const projectQuery = useProjectQuery(projectId);
  const pendingPrompts = useFilterProjectPromptIds(projectId, PromptStatus.PENDING);

  // Do not show another right away banner when the user closes the current banner.
  const [bannerClosed, setBannerClosed] = useState(false);

  const [banner, setBanner] = useState<ProjectBanner | null | undefined>(undefined);
  useAsyncEffect(
    async (checkpoint) => {
      if (user == null || projectQuery.data == null) {
        setBanner(undefined);
        return;
      }
      if (bannerClosed === true) {
        setBanner(null);
      }

      const newBanner = await projectBannerService.getProjectBanner(user, projectQuery.data, pendingPrompts.length);
      checkpoint();
      setBanner(newBanner);
    },
    [user, projectQuery.data, pendingPrompts.length, bannerClosed],
  );

  const closeBanner = useCallback(async () => {
    if (banner == null || banner.closeable == false) {
      return;
    }

    await projectBannerService.closeProjectBanner(banner);
    setBannerClosed(true);
  }, [banner, projectBannerService]);

  return { banner, closeBanner };
}
