import { faLink } from '@fortawesome/pro-solid-svg-icons';

import { RMButton } from '@/components/RMButton/RMButton.js';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer.js';
import { RMText } from '@/components/RMText/RMText.js';
import { genitiveCase } from '@/utils/genitiveCase';
import { isMobile } from '@/utils/isMobile.js';

import { ButtonGroup, Container } from './ProjectInviteByLink.styles.js';

interface PersonInviteByLinkProps {
  onCopy: () => void;
  onDone: () => void;
}

export function ProjectInviteByLink({ onCopy, onDone }: PersonInviteByLinkProps) {
  const mobile = isMobile();

  return (
    <Container>
      <RMText type="sans" size="s" color="on-surface-primary" bold>
        Invite via link
      </RMText>
      <RMSpacer direction="column" spacing="xs" />
      <RMText type="sans" size="s" color="on-surface-primary">
        {`Anyone can use this link to sign up to access stories recorded in this project.`}
      </RMText>
      <RMSpacer direction="column" spacing="xl" />
      <ButtonGroup isMobile={mobile}>
        <RMButton id="invite-dialog-copy-link-button" leftIcon={faLink} onClick={onCopy} autoLoading>
          Copy link
        </RMButton>
        {!mobile && (
          <RMButton id="invite-dialog-done-button" onClick={onDone} background="primary">
            Done
          </RMButton>
        )}
      </ButtonGroup>
    </Container>
  );
}
