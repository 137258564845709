import { ReactNode } from 'react';
import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';

import { RMDialog } from '@/components/RMDialog/index.js';
import { RMIconButton } from '@/components/RMIconButton/RMIconButton.js';
import { RMStack } from '@/components/RMStack/RMStack.js';
import { RMText } from '@/components/RMText/RMText.js';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport.js';
import { isMobile } from '@/utils/isMobile.js';

import { Separator } from './ProjectShareDialog.styles.js';

interface ProjectShareDialogProps {
  open: boolean;
  onOpenHelp: () => void;
  onClose: () => void;
  InviteByEmail: ReactNode;
  InviteByLink: ReactNode;
  UserList: ReactNode;
}

export function ProjectShareDialog({
  open,
  onOpenHelp,
  onClose,
  InviteByEmail,
  InviteByLink,
  UserList,
}: ProjectShareDialogProps) {
  const mobile = useIsMobileViewport();

  return (
    <RMDialog.Root variant={mobile ? 'full-screen' : 'regular'} open={open} onClose={onClose}>
      <RMDialog.Content>
        <RMDialog.Header
          title="Share this project"
          message="Collaborators can view stories and customize prompts."
          leftAdornment={
            mobile ? (
              <RMIconButton
                icon={faChevronLeft}
                tooltip={null}
                backgroundColor="transparent"
                stateColor="darken-neutral"
                onClick={onClose}
              />
            ) : null
          }
          rightAdornment={
            <RMIconButton
              icon={faCircleQuestion}
              tooltip={{ label: 'Support', position: 'bottom' }}
              size="xl"
              backgroundColor="transparent"
              stateColor="darken-neutral"
              onClick={onOpenHelp}
            />
          }
        />
        <RMDialog.Body>
          <RMStack direction="column" spacing="xl">
            {InviteByEmail}
            <RMText type="sans" size="s" color="on-surface-primary" bold>
              People with access
            </RMText>
            {UserList}
            <Separator />
            {InviteByLink}
          </RMStack>
        </RMDialog.Body>
      </RMDialog.Content>
    </RMDialog.Root>
  );
}
