import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMPageBanner } from '@/components/RMPage/RMPageBanner';
import { getAccountReactivatePath, getAccountRenewPromoPath } from '@/modules/routing';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

import { ProjectBannerType, useProjectBanner, useProjectQuery } from '../../../services/api/project';
import { ResumeProjectConfirmationModalContainer } from '../../project-settings/containers/ResumeProjectConfirmationModal.container';

export interface ProjectBannerContainerProps {
  projectId: string;
}

export function ProjectBannerContainer({ projectId }: ProjectBannerContainerProps) {
  const navigate = useNavigate();

  // Queries
  const user = useUser();
  const projectQuery = useProjectQuery(projectId);
  const storytellerPersonQuery = usePersonQuery(projectQuery.data?.notifications.recipientPersonIds[0]);
  const { banner, closeBanner } = useProjectBanner(projectId);
  const [resumeModalOpen, setResumeModalOpen] = useState(false);

  const handleAction = useCallback(() => {
    if (banner == null || user == null || projectQuery.data == null) {
      return;
    }

    switch (banner.type) {
      case ProjectBannerType.PROJECT_PAUSED: {
        setResumeModalOpen(true);
        break;
      }
      case ProjectBannerType.SUBSCRIPTION_INACTIVE: {
        if (user.refIds.includes(projectQuery.data.ownerUserId)) {
          navigate(getAccountReactivatePath());
          return;
        }

        const email = 'support@remento.co';
        const subject = 'Reactivate Remento account';
        const body = [
          'Hi Remento Customer Support,',
          '',
          `I'm reaching out to reactivate the following Remento storyteller's account: ${storytellerPersonQuery.data?.name?.full}.`,
          '',
          'Can you please send me instructions on how to do so?',
        ].join('\n');

        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(mailtoLink);
        break;
      }
      case ProjectBannerType.SUBSCRIPTION_WILL_NOT_RENEW:
      case ProjectBannerType.SUBSCRIPTION_WILL_EXPIRE: {
        navigate(getAccountRenewPromoPath());
        break;
      }
    }
  }, [banner, navigate, projectQuery.data, storytellerPersonQuery.data?.name?.full, user]);

  if (banner == null) {
    return null;
  }

  return (
    <>
      <RMPageBanner
        message={banner.message}
        action={banner.actionLabel}
        style={banner.style}
        showClose={banner.closeable}
        onAction={handleAction}
        onClose={closeBanner}
      />

      {banner.type == ProjectBannerType.PROJECT_PAUSED && (
        <ResumeProjectConfirmationModalContainer
          projectId={projectId}
          open={resumeModalOpen}
          onClose={() => setResumeModalOpen(false)}
        />
      )}
    </>
  );
}
